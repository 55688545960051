/*eslint-disable*/
import React, { useState } from "react"

import { Link } from "gatsby"

import ParallaxSection from "components/ParallaxSection"
// nodejs library that concatenates classes
import classNames from "classnames"
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart"
import Subject from "@material-ui/icons/Subject"
// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"
import CardAvatar from "components/Card/CardAvatar.js"
import Accordion from "components/Accordion/Accordion.js"
import HeroSection from "components/HeroSection"
import LinearProgress from "@material-ui/core/LinearProgress"
import Typography from "@material-ui/core/Typography"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"

// import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js"
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js"
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js"

import { Router } from "@reach/router"

import { gql, useQuery } from "@apollo/client"

import SEO from "../components/Seo"
import useSiteMetadata from "../hooks/use-site-metadata"

import PageLayout from "../components/PageLayout"

// const useStyles = makeStyles(styles)
const useStyles = makeStyles(productStyle)

const ListingsPage = ({location}) => {
  const { cmsURL, baseImageURL } = useSiteMetadata()


  const LISTINGS_QUERY = gql`
    {
      listings: propertiesCollection(sort:{order:1 },filter: { published: true}) {
        _id
        _created
        image {
          path
        }
        title
        title_slug
        content
        gallery {
          path
          meta
        }
        price
      }
    }
  `


  // const TAGS_QUERY = gql`
  //   {
  //     listingsCollection {
  //       tags
  //     }
  //   }
  // `

  const classes = useStyles()

  const { loading, error, data, fetchMore } = useQuery(LISTINGS_QUERY)

  if (loading) return <LinearProgress />
  if (error) return `Error! ${error.message}`

  if (!data.listings) {
    data.listings = []
  }


  const siteURL = "/listing/"

  console.log(data.listings)

  const listings = data.listings.map(listing => {
    const featured_image_url = listing.image
      ? baseImageURL + listing.image.path
      : baseImageURL + listing.gallery[0].path
    return (
      // <a href={siteURL+listing.title_slug}>{listing.title}</a>
      <GridItem xs={12} sm={6} md={6}>
        <Card
          background
          style={{ backgroundImage: `url(${featured_image_url})` }}
        >
          <CardBody background>
            <div style={{backgroundColor:'rgba(0, 0, 0, 0.8)', padding:'0.5rem 0 0.5rem 0', opacity:'0.5'}}>
            <h6 className={classes.cardCategoryWhite} style={{color:'white'}}>Τιμη: {listing.price} €</h6>
            </div>
            <Link to={siteURL + listing.title_slug}>
              <h3 className={classes.cardTitleWhite}>{listing.title}</h3>
            </Link>

          </CardBody>
        </Card>
      </GridItem>
    )
  })

  // const images = data.listingsCollection[0].gallery.map(image => ({original: cmsURL+image.path, thumbnail: cmsURL+image.path}))
  // const header_image = cmsURL+"storage/uploads/2021/03/04/6040ecbbbe6f0103.jpg"
  const header_image = data.listings[0]?.image?baseImageURL+data.listings[0].image.path:baseImageURL+"/2021/03/04/6040ecbbbe6f0103.jpg"

  return (
    <div className={classes.productPage}>
      <PageLayout>
      <SEO
          title="Πωλήσεις"
          description="Τα ακίνητά μας"
          image={header_image}
          pathname={location.pathname}
        />
        {/* <HeroSection
          image={
            "https://images.unsplash.com/photo-1575971637203-d6255d9947a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80"
          }
        /> */}
        <ParallaxSection
          image={header_image}
          filter="dark"
          className={classes.pageHeader}
        />

        {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
        <div className={classNames(classes.section, classes.sectionGray)}>
          <div className={classes.container}>
            {/* <div className={classes.title}>
              <h3>Έργα</h3>
            </div> */}
            <div className={classNames(classes.main, classes.mainRaised)}>
              <Breadcrumbs
                aria-label="breadcrumb"
                style={{ marginBottom: "2rem" }}
              >
                <Link color="inherit" to="/">
                  Αρχική
                </Link>
                <Link color="inherit" to="/listings">
                  Πωλήσεις
                </Link>
              </Breadcrumbs>
              <GridContainer>{listings}</GridContainer>

            </div>
          </div>
        </div>
        {/* </div> */}
      </PageLayout>
    </div>
  )
}

export default ListingsPage
